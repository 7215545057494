
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Maven Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF;
  font-size: 14px;
}

.row {
  margin: 0px;
}

a {
  color: #5e35b1;
  text-decoration: none;
}
a:hover {
  color: #8d5eff;
  text-decoration: underline;
}

hr {
  border-top: 1px solid #ccc;
}

.container {
  padding: 25px 30px;
}

main {
  margin-left: 230px;
}

.main-image { text-align: center }

.login-section{ margin-top: 15px; }

p {
  font-family: 'Maven Pro';
  font-weight: 400;
  color: #222;
  font-size: 1.1em;
}

h1 { font-size: 2.8em; font-family: 'Maven Pro'; font-weight: 800; color: #222; }

.pure-button {
  font-family: 'Maven Pro' !important;
  padding: 0px;
}

.form-custom {
  margin-top: 20px;
}

.form-custom .col-md-6 {
  margin: 0px;
  padding: 0px;
}

.form-custom h3 {
  color: #333;
  border-bottom: 1px solid #f2f2f2;
}

.form-custom h4 {
  color: #333;
  border-bottom: 1px solid #f2f2f2;
}

.field-form {
  display: flex;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin: 10px 3px;
}
.field-form .icon{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.field-form .icon ion-icon{
  color: #d2d8df;
  font-size: 1.9em;
}

.field-form .field{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.field-form .field label{
  color: #424242;
  font-size: 1.05em;
  font-weight: 600;
}
.field-form .field input, .field-form .field textarea, .field-form .field select{
  padding: 5px 1px;
  border: none;
  background-color: transparent;
  color: #222;
  font-size: 1.15em;
  font-weight: 500;
  width: 100%;
  outline: none;
}
.field-form .field input:focus, .field-form .field textarea:focus {
  outline: none;
}

.field-form .checked{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.field-form .checked ion-icon{
  font-size: 1.6em;
}

.buttons-section {
  display: flex;
  align-items: center;
}

.primary-btn {
  border: 0px;
  border-radius: 30px;
  background: #005ec6;
  background: linear-gradient(90deg, #005ec6 0%, #005ec6 96%);
  color: #fff;
  padding: 12px 25px;
  font-weight: 600;
}
.primary-btn:hover, .primary-btn:focus{
  background: #005ec6;
  outline: 0;
}

.green-btn {
  border: 0px;
  border-radius: 30px;
  background: rgb(88,181,174);
  background: linear-gradient(90deg, rgba(88,181,174,1) 0%, rgba(48,154,146,1) 96%);
  color: #fff;
  padding: 12px 25px;
  font-weight: 600;
}
.green-btn:hover, .green-btn:focus{
  background: rgb(88,181,174);
  outline: 0;
}

.blue-btn {
  border: 0px;
  border-radius: 30px;
  background: #005ec6;
  background: linear-gradient(90deg, #005ec6 0%, #127aed 96%);
  color: #fff;
  padding: 12px 25px;
  font-weight: 600;
}
.blue-btn:hover, .blue-btn:focus{
  background: #127aed;
  outline: 0;
}

.dropdown-btn {
  border: 0px;
  border-radius: 30px;
  background: #2ab8d4;
  padding: 12px 20px;
  font-weight: 600;
  font-size: 1em;
  background: linear-gradient(90deg, #2ab8d4 0%, #13c9ed 96%);
}

.primary-link {
  color: #005ec6;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.05em;
}
.primary-link:hover {
  opacity: 0.8;
}

.help-text {
  margin: 0px 10px;
  color: #666;
  flex: 1;
}

.help-text span {
  color: #22A1F2;
  font-size: 1.1em;
}

.help-text ion-icon{
  margin: 0 5px;
  font-size: 1.3em;
}

.social-icons {
  display: flex;
}

.social-icons > div {
  margin: 0 5px
}

.social-icons ion-icon{
  font-size: 2.0em;
}
.follow-facebook {
  color: #177DEA;
}

.follow-twitter {
  color: #22A1F2;
}
.follow-youtube {
  color: #F91100;
}

.text-light {
  font-weight: 300;
  font-size: 1.0em;
}

.logo-mobile { display: none; text-align: center; }

.header-page {
  display: flex;
  margin: 10px 0;
}
.icon-header {
  background: #005ec6;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.icon-header ion-icon{
  font-size: 1.3em;
}

.content-header{
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.content-header p{
  font-weight: 500;
  color: #888;
  font-size: 0.98em;
  padding: 0px;
  margin: 0px;
}
.content-header h4{
  font-weight: 400;
  color: #222;
  font-size: 1.8em;
  padding: 0px;
  margin: 0px;
}

.side-menu {
  width: 100%;
  z-index: 1000;
  height: 100%;
}
.side-menu .icon-app-menu {
  text-align: center;
}

.logo-sidemenu-container {
  margin: 25px 0px;
  width: 100%;
}

.flex-divider{
  flex: 1;
}

.home-page {
  display: flex;
}

.page-content {
  padding: 20px 30px;
}

.menu{
  z-index: 1100;
  position: fixed;
  padding-bottom: 10px;
  width: 230px;
}

.menu-container-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  z-index: 100000;
}

.menu .menu-list {
  flex: 1;
}

.menu ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.menu ul li {
  padding: 8px 17px;
  display: flex;
  align-items: center;
  margin: 10px 15px;
  opacity: 0.76;
  font-size: 1.03em;
}
.menu ul a:hover li{
  opacity: 1;
}
.menu ul a {
  text-decoration: none;
}

.menu ion-icon {
  font-size: 1.45em;
  color: #a9bcd6;
}

.menu ul li p {
  margin: 0px;
  color: #222;
  font-weight: 500;
  flex: 1;
  padding-left: 10px;
}
.menu .icon-chevron i {
  color: #c0c9d2;
  font-size: 0.77em;
}

.menu .powered-by {
  margin: 35px 10px;
  text-align: center;
}

.menu .powered-by p {
  margin: 0px;
  color: #898989;
  font-size: 0.78em;
  font-weight: 600;
}

.menu .powered-by h6 {
  font-weight: 300;
  color: #aeaeae;
  margin: 3px 0px;
  font-size: 0.92em;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #444;
  opacity: 1; /* Firefox */
  font-style: italic;
  font-size: 0.9em;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #444;
  font-style: italic;
  font-size: 0.9em;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #444;
  font-style: italic;
  font-size: 0.9em;
}

.service-type-container {
  display: flex;
  margin: 10px 0;
}

.box-service-type {
  height: 80px;
  width: 100px;
  border-radius: 7px;
  background: #177DEA;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  opacity: 0.9;
  cursor: pointer;
  margin: 0 5px;
}

.box-service-type:hover {
  opacity: 1;
}

.box-service-type p {
  color: #fff;
  margin: 0px;
  padding: 0px
}
.box-service-type ion-icon {
  color: #fff;
  font-size: 2.5em;
}

.topbar {
  display: none;
  height: 100%;
}

.topbar-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px 0px;
}

.topbar-menu img {
  margin: 0 20px;
}

.topbar-menu .hamburger-icon-menu ion-icon{
  color: #222222;
  font-size: 1.9em;
  margin: 0 20px;
}

.topbar-menu .hamburger-icon-menu {
  opacity: 0.9;
  cursor: pointer;
}
.topbar-menu .hamburger-icon-menu:hover{ opacity: 1 }

/* Services */
.service-list h3{
  color: #444;
}
.service-list .link-service-log {
  text-decoration: none;
}
.service-item {
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 7px;
  margin-top: 4px;
  display: flex;
  align-items: center;
}
.service-item:hover{
  cursor: pointer;
  opacity: 0.8;
}
.service-item p {
  padding: 0px;
  margin: 0px;
}

.service-item .icon-chevron{
  color: #C0C9D2;
}
.service-item .service-item-status {
  background-color: #22A1F2;
  border-radius: 4px;
  padding: 8px;
}
.service-item .service-item-status ion-icon {
  color: #fff;
}

.service-item-content {
  flex: 1;
  margin: 0px 10px;
}

aside {
  border: 1px solid red;
  width: 230px;
}

.pure-table {
  box-shadow: 1px 2px 1px #ccc;
  border: none;
}

.pure-table td, .pure-table th {
  border: none;
}

.pure-table thead {
  background-color: #fff;
  color: salmon;
  font-size: 0.99em;
  font-weight: 800;
}
.pure-table tbody {
  background-color: #fff;
  font-family: 'Maven Pro';
  color: #444;
}

table tbody td {
  font-size: 1.05em;
}

.pure-table-odd td {
  background-color: #F8F7FF;
}

.chars-hidden {
  color: #222;
  font-weight: bold;
}

.buttons-table {
  margin: 10px 0px;
  display: flex;
  align-content: flex-end;
}

.logo-center {
  text-align: center;
}

@media (max-width: 768px) {
  .left-login-panel {
    display: none;
  }
  .logo-mobile {
    display: block;
  }
  .side-menu {
    width: 100%;
  }
  main {
    margin-left: 0px;
  }
  .menu {
    width: 100%;
    display: none;
    background-color: #fff; /*#0F163E;*/
    box-shadow: 2px 1px 1px #ccc;
  }
  .topbar{
    display: block;
  }
  .logo-sidemenu-container{
    display: none;
  }
  .menu-container-content {
    height: auto;
  }
  .menu .powered-by {
    text-align: right;
  }
}

/* pagination */
.pagination-table {
  display: flex;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
  background-color: #22A1F2;
  border-color: #22A1F2;
}

.edit-user-icon ion-icon {
  color: #007eff;
  font-size: 1.4em;
}


.point-section {
  margin: 30px 15px;
}

.splash-background {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splash-background p{
  color: #222;
  text-align: center;
}

.logo-disclaimer {
  text-align: center;
}

.button-disclaimer {
  text-align: center;
}

.trial-panel-alert {
  text-align: center;
  background-color: #f2f2f2;
  position: relative;
  width: 100%;
  padding: 2px 10px;
  z-index: 10001;
}

.trial-panel-alert a {
  margin: 0 15px;
}

.discalimer-section h3{
  font-weight: bold;
}

.full-notification {
  margin: 15px 10px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.full-notification ion-icon {
  font-size: 1.9em;
  margin: 0px 10px;
  color: #2FC4B2;
}

.ntf-green {
  border-color: #2FC4B2;
}

/** pagination styles **/
.pagination {
  height: 36px;
  margin: 18px 0;
}
.pagination ul {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
  margin-left: 0;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination li {
  display: inline;
}
.pagination a {
  float: left;
  padding: 0 14px;
  line-height: 34px;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}
.pagination a:hover,
.pagination .active a {
  background-color: #2ab8d4;
}
.pagination .active a {
  color: #fff;
  cursor: pointer;
}
.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #222;
  background-color: transparent;
  cursor: pointer;
}
.pagination li:first-child a {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.pagination-centered {
  text-align: center;
}
.pagination-right {
  text-align: right;
}
.pager {
  margin-left: 0;
  margin-bottom: 18px;
  list-style: none;
  text-align: center;
  *zoom: 1;
}
.pager:before,
.pager:after {
  display: table;
  content: "";
}
.pager:after {
  clear: both;
}
.pager li {
  display: inline;
}
.pager a {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.pager a:hover {
  text-decoration: none;
  background-color: #222;
}
.pager .next a {
  float: right;
}
.pager .previous a {
  float: left;
}
.pager .disabled a,
.pager .disabled a:hover {
  color: #222;
  background-color: #fff;
  cursor: default;
}

.no-registers-content {
  padding: 30px 40px;
}
.no-registers-content li {
  font-size: 1.1em;
}

.header-color-highlight {
  color: #13c9ed !important;
  font-weight: bold;
}

.badget-status-order {
  color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 3px 5px;
}


.edit-field-quantity {
  width: 60px;
  border-radius: 3px;
  border: 1px solid #005ec6;
  margin: 0 15px;
  text-align: center;
}
